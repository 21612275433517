import {
  POST_CREATED,
  POST_DELETED,
  MESSAGE_CREATED,
  MESSAGE_VIEWED,
  FORCE_LOGOUT,
  PAGE_RELOAD,
  ONLINE_USER_COUNT,
  NEWS_CREATED,
  NEWS_UPDATED,
  NEWS_DELETED,
  SIP_TO_WEB,
  CHECK_PAYMENT,
} from "@/constants/events";
import EventBus from "@/bus/eventBus";
import { socket } from "@/plugins/socket";

const listeners = {
  mounted() {
    console.log("this.$options.name: ", this.$options.name);
    if (socket) {
      socket.off(process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + CHECK_PAYMENT);
      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + CHECK_PAYMENT,
        async (eventData) => {
          console.log("eventData=", eventData);
          this.$root.$emit("checkPaymentStatus", eventData);
      });

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + SIP_TO_WEB + '.'+this.user.id,
        async (eventData) => {
          console.log("eventData=", eventData);
          if(this.$options.name === "Navbar" || this.$options.name === "Sidebar") {
            this.room = await eventData.room;
            this.selectedCountry = await eventData.country;
            if(this.roomList.length > 0) {
              let selectedRoom = await this.roomList.find(element => element.code == this.room);
              this.selectedRoom = await selectedRoom || null;
            }
            await localStorage.setItem('selectedCountry', this.selectedRoom?.country);
            await localStorage.setItem("room", eventData.room);
            await this.saveCurrentRoom({room_id: this.selectedRoom.id});
            location.reload();
          }
      });

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + POST_CREATED,
        async (eventData) => {
          if (this.$options.name === "Posts" || this.$options.name == "NewDashboard") {
            if (eventData.data.owner_id !== this.user.id) {
              const sound = require("../assets/redline_honk.mp3");
              new Audio(sound).play();
            }
            if(this.$options.name == "NewDashboard") {
              if (eventData.data.post_type == 2) {
                this.inventoryAllPosts = await [
                  eventData.data,
                  ...this.inventoryAllPosts,
                ];
              } else {
                this.allPosts = await [eventData.data, ...this.allPosts];
              }
            } else if(this.$options.name === "Posts") {
              this.allPosts = await [eventData.data, ...this.allPosts];
            }
          }
          this.$root.$emit("updateFooterBanner");
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + POST_DELETED,
        async (eventData) => {
          if (this.$options.name === "Posts") {
            this.allPosts = this.allPosts.filter(post => !eventData.data.post_id.includes(post.id));
          } else if(this.$options.name === 'NewDashboard') {
            this.inventoryAllPosts = this.inventoryAllPosts.filter(post => !eventData.data.post_id.includes(post.id));
            this.allPosts = this.allPosts.filter(post => !eventData.data.post_id.includes(post.id));
          }
          this.$root.$emit("updateFooterBanner");
        }
      );

      //post messages count
      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + MESSAGE_VIEWED,
        async (eventData) => {
          if (this.$options.name === "Posts" || this.$options.name === 'NewDashboard') {
            this.badgeCount = eventData.data.unReadMessagesCount;
          }
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + ONLINE_USER_COUNT,
        async (eventData) => {
          if (this.$options.name === "Navbar") {
            EventBus.$emit(
              "PARTICIPANT_COUNT",
              eventData.data.online_users_count
            );
          }
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + FORCE_LOGOUT,
        async (eventData) => {
          if (this.$options.name === "Navbar") {
            if (eventData.data.token === localStorage.getItem("access_token")) {
              this.doLogout();
            }
          }
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + NEWS_CREATED,
        async (eventData) => {
          if (this.$options.name === "Sidebar") {
            const sound = require("../assets/news.mp3");
            new Audio(sound).play();
            this.$root.$emit("updateUnreadnews");
            this.$root.$emit("reloadFetchNews");
          }
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + NEWS_UPDATED,
        async (eventData) => {
          if (this.$options.name === "Sidebar") {
            const sound = require("../assets/news.mp3");
            new Audio(sound).play();
            this.$root.$emit("updateUnreadnews");
            this.$root.$emit("reloadFetchNews");
          }
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + NEWS_DELETED,
        async (eventData) => {
          if (this.$options.name === "Sidebar") {
            const sound = require("../assets/news.mp3");
            new Audio(sound).play();
            this.$root.$emit("updateUnreadnews");
            this.$root.$emit("reloadFetchNews");
          }
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + MESSAGE_CREATED,
        async (eventData) => {
          if (
            this.$options.name === "Posts" ||
            this.$options.name === "Messages" ||
            this.$options.name === "MessageCenter" ||
            this.$options.name === "NewDashboard"
          ) {
            this.badgeCount = eventData.data.unReadMessagesCount;
            this.fetchMessages();
            this.page = 1;
            this.fetchPostThreads();
          }
        }
      );

      socket.on(
        process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + PAGE_RELOAD,
        async (eventData) => {
          window.location.reload();
        }
      );
    }
  },
};

export default { listeners };
