export const POST_CREATED = "post.created";
export const POST_DELETED = "post.deleted";
export const MESSAGE_CREATED = "message.created";
export const MESSAGE_VIEWED = "message.viewed";
export const FORCE_LOGOUT = "force.logout";
export const PAGE_RELOAD = "page.reload";
export const ONLINE_USER_COUNT = "online_users_count.updated";
export const NEWS_CREATED = "news.created";
export const NEWS_UPDATED = "news.updated";
export const NEWS_DELETED = "news.deleted";
export const WEB_TO_SIP = "room.change.to_sip";
export const SIP_TO_WEB = "room.change.to_panel";
export const CHECK_PAYMENT = "check.payment";
