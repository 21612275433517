<template>
  <v-main class="mainSub-header">
   <!-- <audio muted autoplay="" id="test"></audio> -->
    <div id="mixedaudio"></div>
    <div class="live-user-wrapper" id="caller_grid">
    </div>
  </v-main>
</template>

<script>
import { BROADCASTING } from "@/constants/services";
import { mapGetters } from "vuex";
export default {
  name: "CurrentBroadcasts",
  data() {
    return {
      broadCastingService: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  mounted() {
    this.broadCastingService = this.user?.services?.findIndex(
      (val) => val.id === BROADCASTING
    );
  },
};
</script>
