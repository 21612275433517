<template>
  <div>
    <Sidebar />
    <div class="new-header">
      <div class="main-header-inr">
        <div class="header-left">
          <button @click="toggleBodyClass" class="btn toggle-btn toggle-btn-border">
            <img src="./../../assets/img/bars.svg" alt="" />
          </button>
          <!-- <a href="/" class="navbar-brand">
            <span>
              <img src="./../../assets/img/redline-new-logo.svg" alt="" />
            </span>
          </a> -->
        </div>
        <div class="header-middle" v-if="$vuetify.breakpoint.width > 991">
          <ul class="main-nav">
            <!-- Button for each menu item, excluding user type 3 -->
            <li v-for="item in user.user_type === 3 ? [] : filteredMenus" :key="item.title"
              @click="conformDailogOpen(item)" :class="[
                isActiveRoute(item) ? 'new-menus active-btn' : 'new-menus',
              ]" v-if="(!item.meta || (item.meta && item.meta?.notInCountry.length > 0 && !item.meta?.notInCountry.includes(country)))">
              <img :src="item.img" alt="" />
              <span v-html="$t(item.title)"></span>
            </li>

            <!-- Button for user type 3 specific menu items -->
            <li v-for="item in user.user_type === 3 ? user3Menu : []" :key="item.title" :href="item.link"
              @click="conformDailogOpen(item)"
              :target="item.title.toLowerCase() === 'navbar.carhistoryreport' ? '_blank' : '_self'" :class="[
                'btn',
                'text-btn',
                isActiveRoute(item) ? 'new-menus active-btn' : 'new-menus',
                'flex-column-class'
              ]">
              <img :src="item.img" alt="" />
              <span v-html="$t(item.title)"></span>
            </li>
 
            <!-- inventory login menu -->
            <li v-if="user.user_type != 3" class="new-menus">
              <a :href=" inventoryUrl + `?auth_details_user=${user.id}`" target="_blank">
                  <img src="./../../assets/img/login.svg" alt="Inventory Login Icon" />
                <span class="menu-title">{{ $t("navbar.inventoryLogin") }}</span>
              </a>
            </li>

          </ul>
        </div>
        <v-spacer></v-spacer>
        <!-- user profile -->
        <!--  TODO: Hide this button until further notice   -->

        <div class="header-right">
          <!-- <v-autocomplete class="roomSelect" outlined v-model="selectedRoom" :items="roomList" @change="changeRoom"
            item-text="label" item-value="code" :label="$t('navbar.room')" :placeholder="$t('navbar.room')" solo
            :dense="true" hide-details="auto" return-object></v-autocomplete> -->
            <v-select class="room-select room-select-header" outlined v-model="selectedRoom" :items="roomList"
            @change="changeRoom" item-text="label" item-value="code" :label="$t('navbar.room')" :placeholder="$t('navbar.room')" return-object></v-select>
          <!-- <v-autocomplete v-if="$vuetify.breakpoint.width > 1100" class="room-select" outlined v-model="room"
            :items="roomList" @change="changeRoom" item-text="name" item-value="code" :label="$t('navbar.room')"
            return-value></v-autocomplete> -->
          <div class="online-members" elevation="0" >
            <h4 v-html='$t("navbar.onlineMembers")'></h4> <label class="online-m-count"><span id="participantCount"> 0 </span></label>
          </div>
          <v-select :menu-props="{ top: false, offsetY: true }" v-model="lang" :item-value="(item) => item.lang"
            :items="languageList" @change="changeLanguage(lang)" solo class="custom-select language-select">
            <template v-slot:selection="{ item }">
              <div class="btn customBtn pattern-btn">
                <img :src="item.image" width="34" />
              </div>
            </template>
            <template v-slot:item="{ item }">
              <img :src="item.image" width="25" class="mr-3 mt-2 mb-2 pa-1" /><span>{{
                item.name
              }}</span>
            </template>
          </v-select>
          <v-btn class="btn customBtn pattern-btn chat-btn" @click="openChat = true"
            v-if="user.user_type != 3">
            <img src="../../assets/img/chat-icon-new.svg" alt="" />
            <v-badge color="red" class="mb-6" :content="badgeCount" v-if="badgeCount !== 0" />
          </v-btn>

          <div class="rd-user-dtl" v-if="user.user_type == 1 || user.user_type == 3">
            <button @click="$router.push({ name: 'Profile' })">
              <p class="btn-profile">👋 {{ this.user.user_detail.representative_name }}</p>
            </button>
          </div>
          <v-btn class="btn customBtn pattern-btn logOut-btn" @click="doLogout">
            <img src="../../assets/img/logout.svg" alt="" />
          </v-btn>
        </div>
      </div>
    </div>
    <chat :show="openChat" @countUnseen="getUnseen" @updateShow="openChat = false"/>
    
    <v-dialog :attach="true" v-model="conformDailog" max-width="500px"
      :content-class="'hide-overflow-y image-slider rd-custom-modal'" persistent>
      <v-card>
        <v-card-title>
          <h4></h4>
          <button class="close-btn" @click="conformDailog = !conformDailog">
            <img src="./../../assets/img/close.svg" alt="">
          </button>
        </v-card-title>
        <v-card-text class="pa-0 text-center">
          <p style="flex-direction: column">
            {{ $t("navbar.confirmChangeMenuText") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize font-16"
            @click="conformDailog = !conformDailog">
            <span>{{ $t("common.no") }}</span>
          </v-btn>
          <v-btn class="btn customBtn pattern-btn ml-2 white--text text-capitalize font-16" @click="goToNext()">
            <span>{{ $t("common.yes") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import { getLaguageList } from "@/plugins/helpers";
import SocketMixin from "@/mixins/SocketMixin";
import { socket } from "@/plugins/socket";
import chat from "../chat/Chat";
import Sidebar from "./Sidebar.vue";
import { countryDetails } from "../../store/modules/countryDetails";
import { WEB_TO_SIP } from "../../constants/events";
const routeRelationships = {
  '/dashboard/vehicle-history': [
    '/dashboard/previous-vehicle-history',
  ],

  //relationship with the parent route to child route
  '/dashboard/billing-invoice': [
    '/dashboard/billing-invoice/dashboard',
    '/dashboard/billing-invoice/create'
  ],
  // Add other parent-child relationships as needed
};
export default {
  name: "Navbar",
  components: {
    chat,
    Sidebar
  },
  mixins: [SocketMixin.listeners],
  data() {
    return {
      room: "",
      selectedRoom: null,
      country: null,
      badgeCount: 0,
      lang: localStorage.getItem("lang"),
      openChat: false,
      languageList: [],
      sideNav: null,
      menus: [
        {
          // icon: "mdi-receipt",
          title: "navbar.posting",
          link: "/dashboard/posting",
          link2: "/posting",
          img: require('@/assets/img/post.svg'),
        },
        {
          // icon: "mdi-account-multiple",
          title: "navbar.membersDirectory",
          link: "/dashboard",
          img: require('@/assets/img/members.svg'),
        },
        {
          // icon: "mdi-account-multiple",
          title: "navbar.carHistory",
          link: "/dashboard/vehicle-history",
          img: require('@/assets/img/car-history.svg'),
        },
        {
          // icon: "mdi-cogs",
          title: "navbar.interchange",
          link: "/dashboard/interchange",
          img: require('@/assets/img/interchange-1.svg'),
          meta: { notInCountry: ['eg', 'es', 'gh'] },
        },
        {
          // icon: "mdi-receipt",
          title: "navbar.oInvoice",
          link: "/dashboard/billing-invoice",
          img: require('@/assets/img/invoice.svg'),
          mobileOnly: true,
        },
      ],
      user3Menu: [
        {
          img: require('@/assets/img/members.svg'),
          title: "navbar.membersDirectory",
          link: "/dashboard/",
          img: require('@/assets/img/members.svg'),
        },
        {
          img: require('@/assets/img/car-history.svg'),
          title: "navbar.carHistoryReport",
          link: process.env.VUE_APP_VIN_REPORTS_LINK,
        },
        {
          img: require('@/assets/img/tabler_recycle.svg'),
          title: "navbar.coreBuying",
          link: "/core-buying-service/",
        },

      ],
      conformDailog: false,
      link: null,
      websiteServicesLink: process.env.VUE_APP_WEBSITE_SERVICES_LINK,
      reportDialog: false,
      reportDialogMessage: "",
      reportData: null,
      inventoryUrl: process.env.VUE_APP_INVENTORY_URL,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      userServices: "user/getUserServices",
      roomList: "members/getRoomList",
      newsTotalUnreadCount: "news/getNewsTotalUnread",
    }),
    currentRouteName() {
      return this.$route.path;
    },
    filteredMenus() {
  if (this.$vuetify.breakpoint.width > 1350) {
    return this.menus.filter(item => !item.desktopOnly); 
  } else if (this.$vuetify.breakpoint.width > 991) {
    return this.menus.filter(item => !item.mobileOnly);
  }
  return this.menus;
},
  },

  async mounted() {
    this.room = await localStorage.getItem("room")
      ? localStorage.getItem("room")
      : null;
    document.getElementById("participantCount").innerText =
      sessionStorage.getItem("onlineUsers")
        ? sessionStorage.getItem("onlineUsers")
        : this.user.online_users_count;
    this.newsTotalUnread();
    this.$root.$on("updateUnreadnews", () => {
      setTimeout(() => {
        this.newsTotalUnread();
      }, 500);
    });

    //Set Country and language based on IP
    var countryData = localStorage.getItem('countryData');
    countryData = JSON.parse(countryData);
    if(!countryData || !countryData.country_code) {
      this.countryDetails();
    }

    this.country = this.user?.user_detail?.country || 'us';
    await this.getRooms({"country": localStorage.getItem('selectedCountry')});
    this.languageList = getLaguageList(this.country);

    var isExist = this.languageList.find((val) => val.lang == this.lang);
    if(!isExist) {
      localStorage.setItem('lang', 'en');
      this.lang = 'en';
    }

    if (this.room !== undefined && this.room !== null) {
      let selectedRoom = this.roomList.find(element => element.code == this.room);
      this.selectedRoom = selectedRoom || null;
    }
  },

  methods: {
    ...mapActions({
      logout: "user/logout",
      getRooms: "members/getRoomList",
      saveCurrentRoom: "members/saveCurrentRoom",
      newsTotalUnread: "news/newsTotalUnread",
      countryDetails: "countryDetails/getCountryDetails",
    }),
    // Method to check if the route is active
    isActiveRoute(item) {

      const currentPath = this.$route.path;
      const mainRoute = item.link;

      if (currentPath === mainRoute || currentPath === item?.link2) {
        return true;
      }

      const childRoutes = routeRelationships[mainRoute];

      if (childRoutes && childRoutes.includes(currentPath)) {
        return true;
      }

      return false;
      // return this.$route.path.startsWith(item.link);
      // return this.$route.path === item.link;

      // console.log("kk", item.title, this.$route.name);
      // return (
      //   item.title.toLowerCase() === `navbar.${this.$route.name}`.toLowerCase() ||
      //   (item.title === 'navbar.marketingCampaign' &&
      //     ['MarketingCampaign', 'MarketingPhone', 'MarketingEmail', 'AddCampaign', 'HistoryCampaign'].includes(this.$route.name)) ||
      //   (item.title === 'navbar.hotlineNews' && ['HotlineNews', 'ViewHotlineNews'].includes(this.$route.name))
      // );
    },
    // Method to get the appropriate icon class
    getIconClass(title) {
      const specificTitles = [
        'navbar.membersDirectory',
        'navbar.carHistoryReport',
        // 'navbar.vinSearch',
        'navbar.interchange',
        // 'navbar.booklog',
        // 'navbar.marketingCampaign',
        'navbar.searchInventory',
        // 'navbar.coreBuyingMember',
        'navbar.invoice',
      ];
      return specificTitles.includes(title) ? 'mb-2' : 'mb-2 coming-soon-menu-color';
    },

    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    },
    removeBodyClass() {
      document.body.classList.remove('sidebar-active');
    },

    async changeRoom() {
      this.room = await this.selectedRoom.code;
      this.selectedCountry = await this.selectedRoom.country;
      await localStorage.setItem('selectedCountry', this.selectedRoom.country);
      await localStorage.setItem("room", this.selectedRoom.code);
      await this.saveCurrentRoom({room_id: this.selectedRoom.id});
      await socket.emit(process.env.VUE_APP_SOCKET_CHANNEL_NAME + ":" + WEB_TO_SIP, {user_id: this.user.id, room: this.room });
      location.reload();
    },

    setAvtarIcon() {
      if (this.user && this.user.user_detail) {
        if (this.user.parent_id) {
          return this.user.user_detail.representative_name.substring(0, 1);
        }
        return this.user.user_detail.company_name.substring(0, 1);
      }
      return "";
    },

    async doLogout() {
      try {
        await this.logout();
        sessionStorage.removeItem("onlineUsers");
        this.$router.push("Login");
      } catch ({ message }) {
        console.log(message);
      }
    },
    async changeLanguage() {
      await loadLanguageAsync(this.lang);
      localStorage.setItem("lang", this.lang);
      console.log(this.lang)

    },

    getUnseen(data) {
      if (data != 0 && data != this.badgeCount) {
        const sound = require("../../assets/chattone.mp3");
        new Audio(sound).play();
      }
      this.badgeCount = data;
    },
    conformDailogOpen(item) {
      let nextLink = (item.title == 'navbar.posting' && this.$vuetify.breakpoint.width > 1351) ? item.link2 : item.link;
      if (this.$route.name == "BillingInvoiceCreate") {
        this.conformDailog = true;
        this.link = nextLink;
      } else {
        if (nextLink.includes("http") || nextLink.includes("www")) {
          window.open(nextLink, '_blank');
        } else {
          if(this.currentRouteName != nextLink) {
            this.$router.push(nextLink);
          }
        }
      }
    },
    goToNext() {
      this.conformDailog = false;
      this.$router.push(this.link);
    },
  },
};
</script>
