import Vue from 'vue';

export function getLaguageList(country) {
    var lang = [];
      lang['us'] = [
        {
          name: "English",
          lang: "en",
          image: require('@/assets/img/united-states.svg'),
        },
        {
          name: "Spanish",
          lang: "es",
          image: require('@/assets/img/spain.svg'),
        },
        {
          name: "Arabic",
          lang: "ar",
          image: require('@/assets/img/egypt.svg'),
        },
      ];
      lang['mx'] = [
        {
          name: "Spanish",
          lang: "es",
          image: require('@/assets/img/mexico.svg'),
        },
        {
          name: "English",
          lang: "en",
          image: require('@/assets/img/united-states.svg'),
        },
        {
          name: "Arabic",
          lang: "ar",
          image: require('@/assets/img/egypt.svg'),
        },
      ];
      lang['eg'] = [
        {
          name: "Arabic",
          lang: "ar",
          image: require('@/assets/img/egypt.svg'),
        },
        {
          name: "English",
          lang: "en",
          image: require('@/assets/img/united-states.svg'),
        },
        {
          name: "Spanish",
          lang: "es",
          image: require('@/assets/img/spain.svg'),
        },
      ];
      lang['es'] = [
        {
          name: "Spanish",
          lang: "es",
          image: require('@/assets/img/spain.svg'),
        },
        {
          name: "English",
          lang: "en",
          image: require('@/assets/img/united-states.svg'),
        },
        {
          name: "Arabic",
          lang: "ar",
          image: require('@/assets/img/egypt.svg'),
        },
      ];
      lang['gh'] = [
        {
          name: "English",
          lang: "en",
          image: require('@/assets/img/united-states.svg'),
        },
        {
          name: "Spanish",
          lang: "es",
          image: require('@/assets/img/spain.svg'),
        },
        {
          name: "Arabic",
          lang: "ar",
          image: require('@/assets/img/egypt.svg'),
        },
      ];
      return lang[country] || lang['us'];
}

Object.defineProperties(Vue.prototype, {
    $help: {
        get() {
            return {
                getLaguageList
            }
        }
    }
})