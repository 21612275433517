<template>
  <div class="fill-height">
    <Navbar />
    <div class="main-body custom-height">
      <CurrentBroadcasts />
      <div class="columns-wrapper">
        <div class="columns-wrap-container">
          <div v-if="$route.name == 'CoreBuyingMember' || $route.name == 'CoreBuying'">
            <div>
              <div class="panel-right">
                <router-view></router-view>
              </div>
            </div>
          </div>
          <div v-else class="columns-wrap-row">
            <div class="col-6">
              <Posts />
            </div>
            <div class="col-6">
              <div class="panel-right fill-height">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import Posts from "../components/Posts";
import { mapGetters } from "vuex";

// Janus Related files

const userData = JSON.parse(localStorage.getItem("user_data"));
let is_sip = userData?.is_sip; // Use optional chaining to prevent errors if userData is null

(async () => {
  if (is_sip === 1) {
    const module = await import("@/plugins/audiobridge_sip");
  } else {
    const module = await import("@/plugins/audiobridge");
  }
})();     

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    Posts,
  },

  data() {
    return {
      dialog: true,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
  },
  mounted() {
    window.addEventListener(
      "storage",
      () => {
        window.location.reload();
      },
      false
    );

    if (!localStorage.getItem("user_data")) {
      localStorage.removeItem("access_token");
      window.location.reload();
    }
  },
};
</script>
